/* Contenitore principale */
.cardtreni-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  /* Card del treno */
  .cardtreni-card {
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 350px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    animation: slideInUp 0.5s ease-in-out;
  }
  
  /* Effetto hover */
  .cardtreni-card:hover {
    transform: translateY(-10px);
  }
  
  /* Header della card */
  .cardtreni-header {
    background-color: #f5f5f5;
    padding: 15px;
    display: flex;
    align-items: center;
  }
  
  /* Logo compagnia ferroviaria */
  .cardtreni-compagnia-logo {
    width: 80px;
    height: auto;
    margin: 0 0 10px 0;
    margin-right: 15px;
  }
  
  /* Informazioni header */
  .cardtreni-header-info {
    flex-grow: 1;
  }
  
  /* Numero del treno */
  .cardtreni-numero-treno {
    font-size: 1.2em;
    margin: 0;
    font-weight: bold;
    color: #333;
  }
  
  /* Destinazione */
  .cardtreni-destinazione {
    margin: 5px 0 0 0;
    font-size: 15px;
    color: #555;
  }
  
  /* Corpo della card */
  .cardtreni-body {
    padding: 15px;
    flex-grow: 1;
  }
  
  /* Informazioni del treno */
  .cardtreni-body p {
    margin: 8px 0;
    color: #555;
    font-size: 1em;
  }
  
  /* Tipologia del treno */
  .cardtreni-tipologia {
    display: flex;
    align-items: center;
    margin: 8px 0;
    color: #555;
    font-size: 1em;
  }
  
  /* Icona tipologia treno */
  .cardtreni-tipologia-icona {
    width: 35px;
    height: auto;
    margin-left: 10px;
  }
  
  /* Stato del treno */
  .cardtreni-stato {
    font-weight: bold;
    color: #FFA500; /* Colore per ritardi */
  }
  
  /* Stato cancellato */
  .cardtreni-cancellato {
    color: #FF0000; /* Rosso per treni cancellati */
  }
  
  /* Responsività */
  @media (max-width: 768px) {
    .cardtreni-container {
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }
  
    .cardtreni-card {
      width: 100%;
      max-width: 100%;
    }
  
    .cardtreni-header {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 20px;
    }
  
    .cardtreni-compagnia-logo {
      margin: 0 0 10px 0;
    }
  
    .cardtreni-header-info {
      margin-bottom: 10px;
    }
  
    .cardtreni-tipologia {
      margin: 8px 0;
      color: #555;
      font-size: 1em;
    }
  
    .cardtreni-tipologia-icona {
      margin-left: 5px;
    }
  }
  
  /* Animazioni */
  @keyframes slideInUp {
    from {
      opacity: 0;
      transform: translateY(20%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
  