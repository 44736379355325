.effetti-background {
    background-color: #f8f9fa;
  }
  
  .effetti-title {
    font-weight: bold;
    font-family: Impact, sans-serif;
    font-size: 60px;
    text-align: center;
    margin-top: 20px;
  }
  
  .effetti-div-centrale {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .effetti-date {
    font-size: 12px;
    color: #6c757d;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .effetti-container {
    padding: 10px;
  }
  
  .effetti-header {
    font-family: 'Arial Black', sans-serif;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .effetti-text {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  
  .effetti-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .effetti-col {
    flex: 1;
    max-width: 45%;
    margin: 10px;
    text-align: center;
  }
  
  .effetti-image {
    width: 100%;
    border-radius: 5px;
  }
  
  .effetti-fonti {
    font-size: 16px;
    color: #495057;
    border-left: 5px solid #007bff;
    padding-left: 10px;
    margin-top: 20px;
  }
  