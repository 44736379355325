/* galleria-photo.css */

/* Contenitore generale */
.galleria-photo-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .galleria-photo-title {
    text-align: center;
    font-size: 2.5em;
    font-weight: bold;
    color: #333;
    margin-bottom: 40px;
  }
  
  /* Spinner di caricamento */
  .galleria-photo-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
  }
  
  .galleria-photo-spinner {
    border: 8px solid #f3f3f3; /* Grigio chiaro */
    border-top: 8px solid #3498db; /* Blu */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: galleria-photo-spin 2s linear infinite;
  }
  
  @keyframes galleria-photo-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Griglia delle foto */
  .galleria-photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;
  }
  
  .galleria-photo-item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  
  .galleria-photo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.3s ease;
  }
  
  .galleria-photo-item:hover .galleria-photo-image {
    transform: scale(1.1);
  }
  
  /* Modal */
  .galleria-photo-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .galleria-photo-modal-content {
    background-color: #fff;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    position: relative;
    border-radius: 8px;
  }
  
  .galleria-photo-close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 2em;
    color: #aaa;
    cursor: pointer;
  }
  
  .galleria-photo-close-button:hover {
    color: #333;
  }
  
  .galleria-photo-modal-title {
    margin-top: 10px;
    font-size: 1.5em;
    color: #333;
  }
  
  .galleria-photo-modal-description {
    margin-top: 15px;
    font-size: 1em;
    color: #555;
  }
  
  /* Responsività */
  @media (max-width: 768px) {
    .galleria-photo-container {
      padding: 15px;
    }
  
    .galleria-photo-title {
      font-size: 2em;
    }
  
    .galleria-photo-grid {
      grid-template-columns: 1fr; /* Mostra una foto per riga su dispositivi mobili */
    }
  
    .galleria-photo-modal-content {
      width: 90%;
    }
  }
  