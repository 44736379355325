.banned-background {
    background-color: black;
    color: white;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .banned-background .modal {
    z-index: 10000; /* Assicurati che il popup sia sopra lo sfondo nero */
  }
  