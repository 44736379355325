/* Contenitore principale */
.inserisci-codice-treno-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Montserrat', sans-serif;
  }
  
  /* Titolo */
  .inserisci-codice-treno-title {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333;
  }
  
  /* Form */
  .inserisci-codice-treno-form {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .inserisci-codice-treno-field {
    margin-bottom: 30px;
    position: relative;
  }
  
  .inserisci-codice-treno-field label {
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    color: #555;
  }
  
  .inserisci-codice-treno-field input {
    width: 100%;
    padding: 14px 20px;
    border: 1px solid #ddd;
    border-radius: 50px;
    font-size: 1em;
    transition: border-color 0.3s;
  }
  
  .inserisci-codice-treno-field input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  }
  
  /* Select wrapper */
  .inserisci-codice-treno-select-wrapper {
    position: relative;
  }
  
  .select-arrow {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-size: 1em;
    color: #555;
    pointer-events: none;
  }
  
  /* Menu a tendina per le stazioni */
  .inserisci-codice-treno-menu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    max-height: 250px;
    overflow-y: auto;
    width: 100%;
    z-index: 10;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-top: -1px;
  }
  
  .inserisci-codice-treno-menu-item {
    padding: 12px 20px;
    cursor: pointer;
    font-size: 0.95em;
    color: #555;
  }
  
  .inserisci-codice-treno-menu-item:hover,
  .inserisci-codice-treno-highlighted-item {
    background-color: #f8f9fa;
  }
  
  /* Pulsante */
  .inserisci-codice-treno-actions {
    text-align: center;
  }
  
  .inserisci-codice-treno-actions button {
    background-color: #007bff;
    color: #fff;
    padding: 14px 40px;
    font-size: 1.1em;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .inserisci-codice-treno-actions button:hover {
    background-color: #0069d9;
  }
  
  .inserisci-codice-treno-actions button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  /* Dettagli del treno */
  .inserisci-codice-treno-details {
    margin-top: 50px;
  }
  
  .inserisci-codice-treno-details h3 {
    font-size: 2em;
    margin-bottom: 30px;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  .train-icon {
    width: 40px;
    margin-right: 15px;
  }
  
  .train-info {
    background-color: #fff;
    padding: 35px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .train-info p {
    margin-bottom: 18px;
    font-size: 1em;
    color: #555;
  }
  
  .train-info p strong {
    color: #333;
  }
  
  .train-info p img.operator-icon {
    width: 30px;
    margin-left: 10px;
    vertical-align: middle;
  }
  
  /* Lista stazioni */
  .stazioni-list {
    list-style-type: none;
    padding-left: 0;
    margin-top: 10px;
  }
  
  .stazioni-list li {
    background-color: #f8f9fa;
    padding: 10px 15px;
    margin-bottom: 8px;
    border-radius: 5px;
    font-size: 0.95em;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .inserisci-codice-treno-container {
      padding: 15px;
    }
  
    .inserisci-codice-treno-title {
      font-size: 2em;
      margin-bottom: 30px;
    }
  
    .inserisci-codice-treno-form {
      padding: 30px;
    }
  
    .inserisci-codice-treno-actions button {
      width: 100%;
    }
  
    .train-info {
      padding: 25px;
    }
  
    .train-info p {
      font-size: 0.95em;
    }
  
    .train-icon {
      width: 35px;
    }
  
    .operator-icon {
      width: 25px;
    }
  }
  
  /* Font Import */
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
  
  
  