/* Stile per il container principale */
.notizie-ferrovie-container {
    background-color: #f0f3f5;
    padding: 20px;
    min-height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  /* Stile per la lista delle notizie */
  .notizie-lista {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Stile per ogni card della notizia */
  .notizia-card {
    background-color: #ffffff;
    border: 1px solid #dcdfe3;
    border-left: 5px solid #007acc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 16px;
    width: 100%;
    max-width: 800px;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .notizia-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Stile per il titolo della notizia */
  .notizia-titolo {
    font-size: 24px;
    font-weight: bold;
    color: #00264d;
    margin-bottom: 10px;
  }
  
  /* Stile per la data della notizia */
  .notizia-data {
    font-size: 14px;
    color: #6c7a89;
    margin-bottom: 15px;
  }
  
  /* Stile per il testo della notizia */
  .notizia-testo {
    font-size: 16px;
    color: #34495e;
    line-height: 1.6;
  }
  
  /* Spinner di caricamento */
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
  
  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #007acc;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Messaggio di errore */
  .error-message {
    color: #e74c3c;
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  
  /* Responsività */
  @media (max-width: 600px) {
    .notizia-card {
      padding: 15px;
    }
  
    .notizia-titolo {
      font-size: 20px;
    }
  
    .notizia-testo {
      font-size: 14px;
    }
  }
  