/* Contenitore principale */
.detailsstation-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 16px;
  }
  
  /* Campo di input e menu */
  .detailsstation-input-container {
    position: relative;
    margin-bottom: 16px;
  }
  
  .detailsstation-select-input {
    width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .detailsstation-menu {
    position: absolute;
    width: 100%;
    background: #fff;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 8px 8px;
    z-index: 10;
  }
  
  .detailsstation-menu-item {
    padding: 10px 16px;
    cursor: pointer;
  }
  
  .detailsstation-menu-item:hover,
  .detailsstation-highlighted-item {
    background-color: #f0f0f0;
  }
  
  /* Bottone */
  .detailsstation-button {
    display: inline-flex;
    align-items: center;
    background-color: #007BFF;
    color: #fff;
    font-weight: bold;
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 8px;
  }
  
  .detailsstation-button:hover {
    background-color: #0056b3;
  }
  
  .detailsstation-button .detailsstation-icon {
    margin-right: 8px;
  }
  
  /* Dettagli */
  .detailsstation-details {
    margin-top: 24px;
  }
  
  .detailsstation-details h3 {
    font-size: 24px;
    margin-bottom: 12px;
  }
  
  .detailsstation-details p {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  /* Mappa */
  .detailsstation-map-container {
    height: 400px;
    width: 100%;
    margin-top: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  /* Responsività */
  @media (max-width: 600px) {
    .detailsstation-select-input {
      font-size: 14px;
      padding: 10px 12px;
    }
  
    .detailsstation-menu-item {
      padding: 8px 12px;
    }
  
    .detailsstation-button {
      width: 100%;
      justify-content: center;
    }
  
    .detailsstation-details h3 {
      font-size: 20px;
    }
  
    .detailsstation-details p {
      font-size: 14px;
    }
  }
  