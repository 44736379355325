/* Importazione del Font */
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

/* Stili Generali */
.autostima-container {
  background-color: #f9f9f9;
  color: #333;
  font-family: 'Lora', 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.autostima-content {
  max-width: 800px;
  padding: 20px;
}

/* Titolo del Sito */
.autostima-site-title {
  font-size: 48px;
  font-weight: bold;
  margin: 40px 0 20px;
  text-align: center;
  color: #222;
  font-family: 'Lora', serif;
}

/* Meta Informazioni dell'Articolo */
.autostima-article-meta {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  text-align: center;
}

/* Titolo dell'Articolo */
.autostima-article-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
  color: #000;
  font-family: 'Lora', serif;
}

/* Sezioni */
.autostima-article .autostima-section {
  margin-bottom: 40px;
}

.autostima-section-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #444;
  font-family: 'Lora', serif;
}

.autostima-article p,
.autostima-article ul {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.autostima-list {
  list-style-type: disc;
  margin-left: 40px;
}

.autostima-list li {
  margin-bottom: 10px;
}

/* Immagini */
.autostima-figure {
  margin: 30px 0;
}

.autostima-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.autostima-figcaption {
  font-size: 16px;
  color: #555;
  margin-top: 10px;
  text-align: center;
}

/* Fonti dell'Articolo */
.autostima-article-sources {
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.autostima-sources-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.autostima-article-sources p {
  font-size: 16px;
  color: #555;
}

/* Design Responsivo */
@media (max-width: 600px) {
  .autostima-site-title {
    font-size: 36px;
    margin: 30px 0 15px;
  }
  
  .autostima-article-title {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .autostima-section-title {
    font-size: 24px;
  }
  
  .autostima-article p,
  .autostima-article ul {
    font-size: 16px;
  }
}


  