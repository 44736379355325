/* shopmaglie.css */

/* Importa font moderni per un aspetto professionale */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

/* Stili generali */
.shopmaglie-container {
  font-family: 'Montserrat', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  padding: 20px 40px;
}

.shopmaglie-container.dark-mode {
  background-color: #1e1e1e;
  color: #f0f0f0;
}

.shopmaglie-header {
  text-align: center;
  margin-bottom: 60px;
}

.shopmaglie-title {
  font-size: 3em;
  margin-bottom: 10px;
}

.shopmaglie-subtitle {
  font-size: 1.2em;
  color: #777;
}

.shopmaglie-search {
  padding: 15px 20px;
  width: 50%;
  max-width: 600px;
  margin: 30px auto;
  display: block;
  border: 2px solid #e91e63;
  border-radius: 30px;
  font-size: 1.1em;
  transition: border-color 0.3s;
}

.shopmaglie-search:focus {
  outline: none;
  border-color: #d81b60;
}

.shopmaglie-toggle-mode {
  margin-top: 20px;
  padding: 12px 30px;
  background-color: #e91e63;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.shopmaglie-toggle-mode:hover {
  background-color: #d81b60;
}

/* Griglia prodotti */
.shopmaglie-products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  animation: fadeIn 0.5s ease-in-out;
}

.shopmaglie-product {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}

.shopmaglie-product:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 30px rgba(0,0,0,0.15);
}

.shopmaglie-product-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.shopmaglie-product-info {
  padding: 20px;
}

.shopmaglie-product-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.shopmaglie-product-text {
  font-size: 1em;
  color: #555;
  margin-bottom: 15px;
  height: 60px;
  overflow: hidden;
}

.shopmaglie-product-price {
  font-size: 1.4em;
  color: #e91e63;
  margin-bottom: 15px;
}

.shopmaglie-product-seller {
  font-size: 0.9em;
  color: #777;
  margin-bottom: 20px;
}

.shopmaglie-add-to-cart {
  background-color: #e91e63;
  color: #fff;
  border: none;
  padding: 12px;
  cursor: pointer;
  border-radius: 30px;
  width: 100%;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.shopmaglie-add-to-cart:hover {
  background-color: #d81b60;
}

/* Modal */
.shopmaglie-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Aggiunto per rendere il popup scrollabile */
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.shopmaglie-modal-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  max-width: 800px;
  width: 90%;
  position: relative;
  animation: zoomIn 0.3s ease;
  max-height: 90vh; /* Limita l'altezza massima e abilita lo scroll */
  overflow-y: auto;
}

.shopmaglie-close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #333;
  transition: color 0.3s;
}

.shopmaglie-close-button:hover {
  color: #e91e63;
}

.shopmaglie-modal-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.shopmaglie-modal-title {
  font-size: 2.5em;
  margin-top: 20px;
}

.shopmaglie-modal-text {
  margin-top: 20px;
  font-size: 1.1em;
  color: #555;
}

.shopmaglie-modal-price {
  font-size: 2em;
  color: #e91e63;
  margin-top: 20px;
}

.shopmaglie-modal-seller {
  font-size: 0.9em;
  color: #777;
  margin-top: 10px;
}

.shopmaglie-modal a {
  display: inline-block;
  margin-top: 30px;
  background-color: #e91e63;
  color: #fff;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 30px;
  font-size: 1.1em;
  transition: background-color 0.3s ease;
}

.shopmaglie-modal a:hover {
  background-color: #d81b60;
}

/* Sezione FAQ */
.shopmaglie-faq-section {
  margin-top: 80px;
}

.shopmaglie-faq-section h2 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 2.5em;
}

.shopmaglie-faq-item {
  margin-bottom: 30px;
}

.shopmaglie-faq-question {
  background-color: #e91e63;
  color: #fff;
  padding: 20px;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 30px;
  transition: background-color 0.3s ease;
}

.shopmaglie-faq-question:hover {
  background-color: #d81b60;
}

.shopmaglie-faq-answer {
  background-color: #f9f9f9;
  padding: 20px;
  border-left: 5px solid #e91e63;
  margin-top: 10px;
  font-size: 1em;
  color: #555;
  border-radius: 0 30px 30px 30px;
}

/* Animazioni */
@keyframes zoomIn {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Stili specifici per desktop */
@media (min-width: 1024px) {
  .shopmaglie-header {
    margin-bottom: 80px;
  }
  
  .shopmaglie-title {
    font-size: 3.5em;
  }
  
  .shopmaglie-products {
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;
  }
  
  .shopmaglie-product {
    padding: 0;
    overflow: hidden;
  }
  
  .shopmaglie-product-image {
    height: 300px;
    transition: transform 0.3s;
  }
  
  .shopmaglie-product:hover .shopmaglie-product-image {
    transform: scale(1.1);
  }
  
  .shopmaglie-product-info {
    padding: 25px;
  }
  
  .shopmaglie-add-to-cart {
    margin-top: 10px;
  }
  
  .shopmaglie-modal-content {
    padding: 50px;
  }
  
  .shopmaglie-modal-title {
    font-size: 3em;
  }
  
  .shopmaglie-modal-price {
    font-size: 2.5em;
  }
  
  .shopmaglie-faq-section h2 {
    font-size: 3em;
  }
  
  .shopmaglie-faq-question {
    font-size: 1.5em;
  }
  
  .shopmaglie-faq-answer {
    font-size: 1.1em;
  }
}

/* Modalità scura - Desktop */
.shopmaglie-container.dark-mode .shopmaglie-product,
.shopmaglie-container.dark-mode .shopmaglie-modal-content,
.shopmaglie-container.dark-mode .shopmaglie-faq-answer {
  background-color: #2e2e2e;
  color: #f5f5f5;
}

.shopmaglie-container.dark-mode .shopmaglie-close-button {
  color: #f5f5f5;
}

.shopmaglie-container.dark-mode .shopmaglie-faq-answer {
  background-color: #333;
}

.shopmaglie-container.dark-mode .shopmaglie-product:hover {
  box-shadow: 0 20px 30px rgba(255,255,255,0.1);
}

.shopmaglie-container.dark-mode .shopmaglie-search {
  background-color: #333;
  color: #f5f5f5;
  border-color: #555;
}

.shopmaglie-container.dark-mode .shopmaglie-search:focus {
  border-color: #777;
}

@media (max-width: 768px) {
  .shopmaglie-header {
    margin-bottom: 30px;
  }
  
  .shopmaglie-title {
    font-size: 2em;
  }
  
  .shopmaglie-search {
    width: 90%;
  }
  
  .shopmaglie-products {
    grid-template-columns: 1fr; /* Le card occupano tutta la larghezza */
    gap: 20px;
  }
  
  .shopmaglie-product {
    padding: 0;
  }
  
  .shopmaglie-product-image {
    width: 100%;
    height: auto;
  }
  
  .shopmaglie-product-info {
    padding: 15px;
  }
  
  /* Modal su mobile */
  .shopmaglie-modal-content {
    padding: 20px;
    width: 95%; /* Occupa quasi tutta la larghezza disponibile */
    max-height: 90vh; /* Limita l'altezza massima per il scroll */
    overflow-y: auto;
  }
  
  .shopmaglie-close-button {
    top: 10px;
    right: 15px;
    font-size: 1.5em;
  }
}

/* Modalità scura - Mobile */
.shopmaglie-container.dark-mode .shopmaglie-product,
.shopmaglie-container.dark-mode .shopmaglie-modal-content,
.shopmaglie-container.dark-mode .shopmaglie-faq-answer {
  background-color: #2e2e2e;
  color: #f5f5f5;
}

/* Altri stili... */

/* Animazioni */
@keyframes zoomIn {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}






  
  


  
  