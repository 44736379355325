/* AccountDashboardLayout.css */

.account_dsh_container {
    min-height: 100vh;
    background-color: #f7fafc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .account_dsh_title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 40px;
    color: #2d3748;
    text-align: center;
  }
  
  .account_dsh_menu {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
  }
  
  @media (min-width: 640px) {
    .account_dsh_menu {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .account_dsh_menu {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .account_dsh_menu_item {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.2s ease-in-out;
  }
  
  .account_dsh_menu_item:hover {
    transform: scale(1.05);
  }
  
  .account_dsh_icon {
    color: #3182ce;
    margin-bottom: 20px;
  }
  
  .account_dsh_section_name {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .account_dsh_link {
    color: #3182ce;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
  }
  
  .account_dsh_link:hover {
    color: #2b6cb0;
  }
  
  .account_dsh_logout_button {
    margin-top: 40px;
    background-color: #e53e3e;
    color: #ffffff;
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .account_dsh_logout_button:hover {
    background-color: #c53030;
  }
  
  .account_dsh_logout_button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(197, 48, 48, 0.5);
  }
  