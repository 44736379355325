/* Spinner CSS */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .spinner {
    border: 8px solid rgba(255, 255, 255, 0.2);
    border-top: 8px solid white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  /* Slow transition for menu button */
  .menu-button-transition {
    transition: all 0.5s ease-in-out;
  }
  
  /* Slow open/close animation */
  .menu-open-close {
    transform-origin: top;
    transition: transform 1s ease-in-out;
  }
  
  /* Navbar panel animation */
  @keyframes slideDown {
    0% { transform: translateY(-100%); }
    100% { transform: translateY(0); }
  }
  
  .navbar-panel-enter {
    animation: slideDown 1s forwards;
  }
  
  .navbar-panel-leave {
    animation: slideDown 1s reverse forwards;
  }
  
  /* Button animation */
  .button-animation {
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .button-animation:hover {
    background-color: #ffcc00; /* Giallo */
    transform: scale(1.05);
  }
  
  .button-animation:active {
    background-color: #e6b800; /* Giallo scuro */
    transform: scale(1.1);
  }
  
  
  
  
  