/* listaTreni.css */
:root {
  --primary-color: #4299e1;
  --primary-color-light: rgba(66, 153, 225, 0.4);
  --text-color: #4a5568;
  --bg-color: #ffffff;
  --border-color: #e2e8f0;
  --hover-bg-color: #edf2f7;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

.lista-treni__select-container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.lista-treni__label {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-color);
  padding-left: 0.25rem;
}

.lista-treni__input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 2px solid var(--border-color);
  border-radius: 0.5rem;
  background-color: var(--bg-color);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.lista-treni__input:focus,
.lista-treni__input:hover {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 8px var(--primary-color-light);
}

.lista-treni__menu {
  max-height: 200px;
  overflow-y: auto;
  border: 2px solid var(--border-color);
  border-radius: 0.5rem;
  background-color: var(--bg-color);
  margin-top: 0.25rem;
  padding: 0;
  list-style: none;
  box-shadow: 0 4px 6px var(--shadow-color);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.lista-treni__menu-item {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.lista-treni__menu-item:hover {
  background-color: var(--hover-bg-color);
}

.lista-treni__menu-item--highlighted {
  background-color: var(--primary-color-light);
  font-weight: 500;
}


  
  