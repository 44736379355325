/* Stili specifici per la pagina Creazionevideogiochi */
.creazionevideogiochi-background {
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding: 20px;
}

.creazionevideogiochi-title {
    font-style: bold;
    font-family: 'Impact', sans-serif;
    font-size: 60px;
    color: white;
    text-align: center;
}

.creazionevideogiochi-div-centrale {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
}

.creazionevideogiochi-date {
    margin-left: 8px;
    font-size: 12px;
    color: #555;
}

.creazionevideogiochi-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.creazionevideogiochi-article-title {
    font-family: 'Arial Black', sans-serif;
    margin-left: 8px;
    font-size: 24px;
    color: #333;
}

.creazionevideogiochi-article-text {
    margin-left: 10px;
    font-size: 16px;
    color: #555;
    line-height: 1.6;
}

.creazionevideogiochi-row {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
}

.creazionevideogiochi-col {
    flex: 1;
    padding: 15px;
}

.creazionevideogiochi-img-fluid {
    max-width: 100%;
    height: auto;
}

.creazionevideogiochi-rounded {
    border-radius: 10px;
}

.creazionevideogiochi-model-viewer {
    width: 100%;
    height: 500px;
    margin-top: 20px;
}

.creazionevideogiochi-subtitle {
    font-family: 'Arial Black', sans-serif;
    margin-left: 8px;
    font-size: 20px;
    color: #333;
}