/* Stili generali (per desktop) */
.zr_app-container { 
  text-align: center; 
  background-color: #282c34; 
  color: white; 
  min-height: 100vh; 
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  font-size: calc(10px + 2vmin); 
} 
.zr_app-header { 
  font-size: 2rem; 
  margin-bottom: 20px; 
} 
.zr_click-to-show { 
  background-color: #ff6f61; 
  color: white; 
  padding: 10px 20px; 
  margin-top: 20px; 
  font-size: 1rem; 
  cursor: pointer; 
  border-radius: 5px; 
  transition: background-color 0.3s; 
} 
.zr_click-to-show:hover { 
  background-color: #e05550; 
} 
.zr_podium { 
  display: flex; 
  justify-content: center; 
  align-items: flex-end; 
  gap: 20px; 
  flex-wrap: wrap; 
} 
.zr_animated-card { 
  background-color: #61dafb; 
  margin: 40px; 
  padding: 40px; 
  border-radius: 20px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  transition: transform 0.3s, box-shadow 0.3s; 
  cursor: pointer; 
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  width: 350px; /* Width fissa per migliorare l'allineamento */ 
} 
.rank-1 { 
  background-color: #ffd700; 
} 
.rank-2 { 
  background-color: #c0c0c0; 
} 
.rank-3 { 
  background-color: #cd7f32; 
} 
.zr_animated-card:hover { 
  transform: scale(1.05); 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
} 
.zr_card-icon { 
  margin-top: 10px; 
  width: 40px; 
  height: 40px; 
}

/* Stili specifici per dispositivi mobili */
@media (max-width: 768px) { 
  .zr_podium { 
      flex-direction: column; 
      align-items: center; 
  } 
  .zr_app-header { 
      font-size: 1.5rem; 
  } 
  .zr_click-to-show { 
      font-size: 0.8rem; 
      padding: 8px 16px; 
  } 
  .zr_animated-card { 
      width: 80%; 
      padding: 15px; 
  } 
  .zr_card-icon { 
      width: 30px; 
      height: 30px; 
  } 
}

  
  
  
  
  
  
  
  
  
  
  