.casabianca-page {
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .casabianca-title {
    font-weight: bold;
    font-family: Impact, sans-serif;
    font-size: 60px;
    text-align: center;
    margin-top: 20px;
  }
  
  .casabianca-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .casabianca-date {
    font-size: 12px;
    color: #6c757d;
    margin-bottom: 20px;
  }
  
  .casabianca-article {
    font-family: Arial, sans-serif;
  }
  
  .casabianca-header {
    font-family: 'Arial Black', sans-serif;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .casabianca-subheader {
    font-family: 'Arial Black', sans-serif;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .casabianca-text {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  
  .casabianca-list {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .casabianca-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .casabianca-col {
    flex: 1;
    max-width: 45%;
    margin: 10px;
    text-align: center;
  }
  
  .casabianca-image {
    width: 100%;
    border-radius: 5px;
  }
  
  .casabianca-caption {
    font-size: 12px;
    color: #6c757d;
    margin-top: 5px;
  }
  
  .casabianca-fonti {
    font-size: 16px;
    color: #495057;
    border-left: 5px solid #007bff;
    padding-left: 10px;
    margin-top: 20px;
  }
  
  