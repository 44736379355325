@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
}



